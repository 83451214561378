<template>
<el-container class="container backBoxbb" ref="container" 
    style="display: flex;flex-direction:column;" >

<el-main ref="elmaincards1" style="justify-content: flex-start;display: flex;flex-direction:column;">
  <div class="flex_row" style="max-width:700px;width:100%;flex-wrap:wrap;">
    <div class="flex_row" style="width:100%;max-width:350px;">
    <el-date-picker
      v-model="valueDate[0]" style="max-width:250px;margin:0px 0px 10px 0;"
      type="date" value-format="yyyy-MM-dd"
      placeholder="开始日期" :size="size">
    </el-date-picker><span style="line-height: 32px;margin:0px 5px 10px">—</span>
    <el-date-picker
      v-model="valueDate[1]" style="max-width:250px;margin:0px 10px 10px 0;"
      type="date" value-format="yyyy-MM-dd"
      placeholder="结束日期" :size="size">
    </el-date-picker>
    </div>
    <div class="flex_row" style="width:100%;max-width:350px;">
      <el-select v-model="station" clearable :size="size" 
          placeholder="站点" style="margin:0 10px 10px 0;flex-grow:1">
        <el-option
          v-for="item in stationList"
          :key="item.station"
          :label="item.station"
          :value="item.station">
        </el-option>
      </el-select>
      <el-input :size="size" v-model="usecode" 
        :maxlength="11" clearable style="margin:0px 10px 10px 0;">
        <span slot="prepend">气瓶</span>
      </el-input>
    </div>
  </div>
  <div class="flex_row" style="max-width:700px;width:100%;flex-wrap:wrap;">
    <el-button type="success"  :size="size"  class="button" :plain="activebt!=1" style="margin:0 10px 10px 0;"
      @click="getOverhaul(1)">{{btCaption[0]}}</el-button>
    <el-button type="success"  :size="size"  class="button" :plain="activebt!=2" style="margin:0 10px 10px 0;"
      @click="getOverhaul(2)">{{btCaption[1]}}</el-button>
    <el-button type="success"  :size="size"  class="button" :plain="activebt!=3" style="margin:0 10px 10px 0;"
      @click="getOverhaul(3)">{{btCaption[2]}}</el-button>
    <el-button type="success"  :size="size"  class="button" :plain="activebt!=4" style="margin:0 10px 10px 0;"
      @click="getOverhaul(4)">{{btCaption[3]}}</el-button><div style="flex-grow:1;"></div>
    <el-button type="primary"  :size="size"  class="button" style="margin:0 10px 10px 0;" :loading="downfileloading"
      @click="exportToExcel">导出</el-button>
  </div>
  <el-table v-if="activebt<=1"  height="700" :size="size" ref="multipleTable" :data="QueryData1" highlight-current-row 
    v-loading="listLoading" element-loading-text="Loading..." :fit="true" :key="refrsh"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.65)"
				border tooltip-effect="dark" :header-cell-style="tableHeaderColor"  >
      <div slot="empty" >暂无数据</div>
      <el-table-column prop="px" label="序" width="40" align1="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="station" label="站点"  min-width="70"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="sqren" label="申请人"  min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="kg15" label="入库15kg"  min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="kg15j" label="入库15kg进"  min-width="70"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="kg50" label="入库50kg"  min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="zdscaner" label="入库人"  min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="tagid" label="扫码次数"  min-width="80"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="xs15" label="销15kg"  min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="xs15j" label="销15kg进"  min-width="70"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="xs15" label="销50kg"  min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="zdflag" label="入库上报" min-width="60"  show-overflow-tooltip></el-table-column>
    </el-table>
  <el-table v-if="activebt==2"  height="700" :size="size" ref="multipleTable" :data="QueryData2" highlight-current-row 
    v-loading="listLoading" element-loading-text="Loading..."  :fit="true" :key="refrsh"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.65)"
				border tooltip-effect="dark" :header-cell-style="tableHeaderColor"  >
      <div slot="empty" >暂无数据</div>
      <el-table-column prop="px" label="序" width="40" align1="center" >
      </el-table-column>
      <el-table-column prop="station" label="站点" width="90"  min-width="70"  ></el-table-column>
      <el-table-column prop="sqren" label="申请人" width="90" min-width="60" show-overflow-tooltip ></el-table-column>
      <el-table-column prop="zddate" label="入库日期" width="100" min-width="90"  ></el-table-column>
      <el-table-column prop="kg15" label="入15kg" width="80" min-width="60"  ></el-table-column>
      <el-table-column prop="kg15j" label="入15kg进" width="90" min-width="70"  ></el-table-column>
      <el-table-column prop="kg50" label="入50kg" width="80" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="zdscaner" label="入库人" width="80" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="tagid" label="扫码次数" width="90" min-width="80"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="xs15" label="销15kg" width="80" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="xs15j" label="销15kg进" width="90" min-width="70"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="xs15" label="销50kg" min-width="70"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="zdflag" label="入库上报" min-width="60"  show-overflow-tooltip></el-table-column>
    </el-table>
  <el-table v-if="activebt==3"  height="700" :size="size" ref="multipleTable" :data="QueryData3" highlight-current-row 
    v-loading="listLoading" element-loading-text="Loading..."  :fit="true" :key="refrsh"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.65)"
				border tooltip-effect="dark" :header-cell-style="tableHeaderColor"  >
      <div slot="empty" >暂无数据</div>
      <el-table-column prop="px" label="序" width="40" align1="center" >
      </el-table-column>
      <el-table-column prop="station" label="站点" width="60"  min-width="50"  ></el-table-column>
      <el-table-column prop="sqren" label="申请人" width="70" min-width="60" show-overflow-tooltip ></el-table-column>
      <el-table-column prop="sqdate" label="申请日期" width="100" min-width="90"  ></el-table-column>
      <el-table-column prop="yylx" label="气种名称" width="90" min-width="70" show-overflow-tooltip ></el-table-column>
      <el-table-column prop="kg15" label="入15kg" width="70" min-width="60"  ></el-table-column>
      <el-table-column prop="kg15j" label="入15kg进" width="85" min-width="70"  ></el-table-column>
      <el-table-column prop="kg50" label="入50kg" width="70" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="zdscaner" label="入库人" width="70" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="zddate" label="入库日期" width="100" min-width="90"  ></el-table-column>
      <el-table-column prop="zdtime" label="入库时间" width="100" min-width="90" show-overflow-tooltip ></el-table-column>
      <el-table-column prop="tagid" label="气瓶编号" width="90" min-width="80"  ></el-table-column>
      <el-table-column prop="xsid" label="销售ID" width="80" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="shenh" label="审核" width="50" min-width="70"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="shenhtime" label="审核时间" width="80" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="zdflag" label="入库上报"  min-width="70"  show-overflow-tooltip></el-table-column>

    </el-table>
  <el-table v-if="activebt==4"  height="700" :size="size" ref="multipleTable" :data="QueryData4" highlight-current-row 
    v-loading="listLoading" element-loading-text="Loading..."  :fit="true" :key="refrsh"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.65)"
				border tooltip-effect="dark" :header-cell-style="tableHeaderColor"  >
      <div slot="empty" >暂无数据</div>
      <el-table-column prop="px" label="序" width="40" align1="center" >
      </el-table-column>
      <el-table-column prop="station" label="站点" width="90"  min-width="70"  ></el-table-column>
      <el-table-column prop="sqren" label="申请人" width="90" min-width="60" show-overflow-tooltip ></el-table-column>
      <el-table-column prop="sqdate" label="申请日期" width="100" min-width="90"  ></el-table-column>
      <el-table-column prop="skg15" label="申15kg" width="80" min-width="60"  ></el-table-column>
      <el-table-column prop="skg15j" label="申15kg进" width="90" min-width="70"  ></el-table-column>
      <el-table-column prop="skg50" label="申50kg" width="80" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="zdscaner" label="入库人" width="80" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="zddate" label="入库日期" width="90" min-width="80"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="rkg15" label="入15kg" width="80" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="rkg15j" label="入15kg进" width="90" min-width="70"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="rkg50" label="入50kg" width="80" min-width="70"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="kg15" label="库15kg" width="80" min-width="60"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="kg15j" label="库15kg进" width="90" min-width="70"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="kg50" label="库50kg" min-width="70"  show-overflow-tooltip></el-table-column>

    </el-table>
</el-main>
<el-pagination style="margin-bottom:5px;" 
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page.sync="pageno"
        :page-sizes="[20, 50, 100]"
        :page-size="pagesize"
        :layout="pagelayout"
        :total="totalcount">
      </el-pagination>
</el-container>  
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      size: 'medium',//medium,small,mini,
      tableHeight:500,
      listLoading:false,
      downfileloading:false,
      valueDate:['2022-02-01','2022-03-06'],
      station:"",
      stationList:[],
      QueryData1:[],
      QueryData2:[],
      QueryData3:[],
      QueryData4:[],
      usecode:"",
      activebt:0,
      pageno:1,
      pagesize:20,
      totalcount:0,//记录总数
      pagelayout:"total,sizes,prev,pager,next,jumper",
      btCaption:['按气种','按日期','按明细','按申请'],
      refrsh:true,
      
    }
  },
  watch:{

  },
  computed: {
    
  },
  mounted(){
    this.valueDate=[this.$api.formatDate(new Date()).substring(0,8)+'01',this.$api.formatDate(new Date())]
    this.getStationList();
    this.initDivSize();
  },
  methods: {
    exportToExcel(){
      this.downfileloading=true;
      let data = {
        lx: "getoverhaulexcel",
        sqlone:this.activebt,
        procext:"report"
      };
      this.$api.post("",data,(res) => {
          if (res["status"] == "1") {
            var headers=res["headers"].split(',')
			      var filterVal=res["fields"].split(',')
            var filetitle=res["filetitle"]
            var jdata=res.records;
            if (jdata.length==0){
              this.$message("无数据，请先查询");
              this.downfileloading=false;
              return
            }
            //console.log("拿到数据。。。。",jdata)
            //console.log(headers,filterVal,filetitle)
            this.$api.export2Excel(headers, filterVal, jdata, filetitle);
            this.downfileloading=false;
            return;
          }else{
            this.$message.error(res.message);
          }
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
          this.downfileloading=false;
        },
        (failure) => {}
      );
    },
    handleSizeChange(val) {
      this.pagesize=val
      let pagesizes=JSON.parse(localStorage.getItem("pagesizes") || "{}");
      pagesizes["Overhaul"]=val
      localStorage.setItem("pagesizes",JSON.stringify(pagesizes))
      if (this.totalcount==0) return
      this.getOverhaul(0)
    },
    handlePageChange(val) {
      if (this.totalcount==0) return
      this.pageno=val
      this.getOverhaul(0)
    },
    dateRange(e){
      console.log(e,this.valueDate)
    },
    getOverhaul(bt){
      this.listLoading=true
      let para={};
      if (this.valueDate!=null){
        para.ksrq=this.valueDate[0]
        para.jsrq=this.valueDate[1]
      }
      para.usecode=this.usecode;
      para.station=this.station;
      if (bt>0)this.activebt=bt;
      let data = {
        lx: "getoverhaul"+(bt>0?bt:this.activebt),
        sqlone:(bt>0?bt:this.activebt),
        sqltwo:JSON.stringify(para),
        pageno:(bt>0?-1:this.pageno),
        pagesize:this.pagesize,
        procext:"report"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.totalcount=parseInt(res.totalcount)
            this.pageno=parseInt(res.pageno)
            this.pagesize=parseInt(res.pagesize)
            //console.log(res.records)
            if (this.activebt==1)
              this.QueryData1=res.records
            if (this.activebt==2)
              this.QueryData2=res.records
            if (this.activebt==3)
              this.QueryData3=res.records
            if (this.activebt==4)
              this.QueryData4=res.records
            this.refrsh=!this.refrsh;
          }else
            this.$message.error(res["message"]);
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
          this.listLoading=false
        },
        (failure) => {}
      );
    },
    EnterFun(e){

    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #f0f0f0;'
        }
    },
    getStationList(){
      let data = {
        lx: "getstationlist",
        procext:"report"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.stationList=res.records
            return
          }else
            this.$message.error(res["message"]);
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    initDivSize() {
      this.isPc=this.$store.getters.GettabBarInfo("isPc");
      if (this.isPc){
        //this.pagelayout="total,sizes,prev,pager,next,jumper"
      }else{
        this.pagelayout="total,prev,pager,next"
        this.btCaption=['气种','日期','明细','申请']
      }
      
    },
  },
  created() {
   let pagesizes=JSON.parse(localStorage.getItem("pagesizes") || "{}");
    if (pagesizes && pagesizes["Overhaul"])
      this.pagesize=parseInt(pagesizes["Overhaul"])
    //this.getOverhaul(2)
  },
};
</script>
<!--公共样式(或者是width: 0;)-->

<style src = "../../Styles/cover.css"></style>
<style src = "../../Styles/common.css"></style>
<style src = "../../Styles/iconfont/iconfont.css"></style>

<style>
.backBoxbb .el-main {
  padding: 10px 20px 5px 10px !important;
}
.backBoxbb .flex_row .el-input-group__prepend{
  padding: 0 10px !important;
}
</style>

<style scoped>
.myscrollbar::-webkit-scrollbar{
display:none;
}
.container {
  width: 100%;
  height: 100%;

}
.flex_row{
    display: flex;             /*设置为flex布局*/
    justify-content: flex-start;   /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: row;
}
.flex_col{
    display: flex;             /*设置为flex布局*/
    justify-content: flex-start;   /*水平居中*/
    align-items: flex-start;       /*垂直居中*/
    flex-direction: column;
}


</style>